// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("./../../../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-405-js": () => import("./../../../src/pages/405.js" /* webpackChunkName: "component---src-pages-405-js" */),
  "component---src-pages-index-0-js": () => import("./../../../src/pages/index0.js" /* webpackChunkName: "component---src-pages-index-0-js" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-templates-about-2-js": () => import("./../../../src/templates/about-2.js" /* webpackChunkName: "component---src-templates-about-2-js" */),
  "component---src-templates-contact-1-js": () => import("./../../../src/templates/contact-1.js" /* webpackChunkName: "component---src-templates-contact-1-js" */),
  "component---src-templates-default-1-js": () => import("./../../../src/templates/default-1.js" /* webpackChunkName: "component---src-templates-default-1-js" */),
  "component---src-templates-home-1-js": () => import("./../../../src/templates/home-1.js" /* webpackChunkName: "component---src-templates-home-1-js" */),
  "component---src-templates-home-2-js": () => import("./../../../src/templates/home-2.js" /* webpackChunkName: "component---src-templates-home-2-js" */),
  "component---src-templates-portfolio-1-js": () => import("./../../../src/templates/portfolio-1.js" /* webpackChunkName: "component---src-templates-portfolio-1-js" */),
  "component---src-templates-portfolio-list-1-js": () => import("./../../../src/templates/portfolio-list-1.js" /* webpackChunkName: "component---src-templates-portfolio-list-1-js" */),
  "component---src-templates-team-1-js": () => import("./../../../src/templates/team-1.js" /* webpackChunkName: "component---src-templates-team-1-js" */)
}

